/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";

// Replace above style with this SCSS file to enable dark mode styles
// @import "./assets/sass/style.dark";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;}

body.modal-open {
    overflow: auto !important;
}

.jstree-default .jstree-anchor {
    font-size: 11px !important;
 }

 .button-red-post {
    background: #ba1323;
    border-radius: 15px;
    border: 0px;
    height: 52px;
    color: white !important;
    font-style: normal;
    font-weight: 100;
    font-size: 20px;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
    padding: 20px;
    text-decoration: none !important;
    display: inline-block;
  }

  .button-blue-post{
    background: #004686;
    border-radius: 15px;
    border: 0px;
    height: 52px;
    color: white !important;
    font-style: normal;
    font-weight: 100;
    font-size: 20px;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
    padding: 20px;
    text-decoration: none !important;
    display: inline-block;
  }

  .cdk-overlay-container {
    position: fixed;
    z-index: 100000 !important;
  }